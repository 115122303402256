
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { Vue } from "vue-class-component";
import mappingDataPdf from "./manifest-pdf-v2";
import { StiDestScController } from "@/app/ui/controllers/StiDestScController";

export default class Generate extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  async fetchPrintDataList(id: number) {
    return StiDestScController.getPrintDataList({
      id: id
    });
  }

  get printDataList() {
    return StiDestScController.printDataList;
  }

  async print(item: any) {
    MainAppController.showLoading();
    const printResult = await this.fetchPrintDataList(item.stiDestScId);

    if (printResult) {
      const printData = this.printDataList;
      const element: any = document;
      const logo: any = element.getElementById("logo").src;
      mappingDataPdf(printData, logo);
    }
    MainAppController.closeLoading();
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
