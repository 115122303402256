import { ChangeStiDestScRequestInterface } from "../contracts/StiDestScRequest";

/* eslint-disable @typescript-eslint/camelcase */
export class GenerateApiRequest implements ChangeStiDestScRequestInterface {
  private readonly sttNumbers: Array<any>;
  constructor(sttNumbers: Array<any>) {
    this.sttNumbers = sttNumbers;
  }
  public toJSON(): string {
    return JSON.stringify({
      stt_bag_cargo: this.sttNumbers.map((item: any) => {
        return {
          stt_no: item.sttNumber,
          bag_code: item.bagNumber,
          cargo_no: item.cargoNumber
        };
      })
    });
  }
}
