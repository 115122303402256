
import { Vue, Options } from "vue-class-component";
import Print from "@/app/ui/views/incoming-process/sti-dest-sc/component/modules/generate.vue";
import { StiDestScController } from "@/app/ui/controllers/StiDestScController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { INCOMING_PROCESS_STI_DEST_SC } from "@/app/infrastructures/misc/RolePermission";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";

@Options({
  components: {
    Print,
    EmptyState
  },
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
    ableToCreate() {
      return checkRolePermission(INCOMING_PROCESS_STI_DEST_SC.CREATE);
    }
  }
})
export default class StationTransitInDestSc extends Vue {
  refs: any = "";
  mounted() {
    this.refs = this.$refs.print;
  }

  fetchStiDestScList() {
    StiDestScController.fetchStiDestScList();
  }

  // loading
  get isLoading() {
    return StiDestScController.isLoading;
  }

  // error cause
  get errorCause() {
    return StiDestScController.errorCause;
  }

  // filter date
  get startDate() {
    return StiDestScController.filter.startDate;
  }

  get endDate() {
    return StiDestScController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    StiDestScController.setStartDate(value[0]);
    StiDestScController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchStiDestScList();
  }

  // filter search
  get searchValue() {
    return StiDestScController.filter.search;
  }

  onSearch(value: string) {
    StiDestScController.setSearch(value);
    this.fetchStiDestScList();
  }

  clearSearch() {
    StiDestScController.setSearch("");
    this.fetchStiDestScList();
  }

  // go to update sti-dest
  goToUpdate() {
    this.$router.push("/sti-dest-sc/update");
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        }
      },
      {
        name: "Kota Kedatangan",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestScArrivalCityName}</div>`;
        },
        tooltipHeader: () => {
          return `<div class="text-black-lp-300 font-semibold text-12px">Kota Kedatangan</div><div class='mt-2 text-gray-lp-500 text-12px'>Kota Kedatangan adalah kota dimana barang/STT tiba ketika diproses pada fitur STI-DEST.</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestScTotalStt.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestScTotalPiece.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Total Gross Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiDestScTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Total Volume Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiDestScTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal STI-DEST-SC",
        styleHead: "w-56 text-left",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.stiDestScCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500 text-12px">${
              item.stiDestScCreatedBy
            }</div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-32 text-left",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  // custom button
  customButton = {
    outline: true,
    title: "Print",
    textColor: "black-lp-300",
    color: "gray-lp-400",
    small: false,
    clickFunction: (item: any) => {
      this.refs.print(item);
    }
  };

  get pagination() {
    return StiDestScController.stiDestScList.pagination;
  }

  get stiDestScList() {
    return StiDestScController.stiDestScList.data;
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    StiDestScController.setDestinationCityCode(event.cityDestinationCode);
    this.fetchStiDestScList();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    StiDestScController.setDestinationCityCode("");
    this.fetchStiDestScList();
  }, 500);
  advancedFilterData: any = "";
}
