import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  StiDestScEntities,
  PrintDataList,
  DetailSttDestScInformation
} from "@/domain/entities/StiDestSc";
import { container } from "tsyringe";
import { StiDestScPresenter } from "../presenters/StiDestScPresenter";
import { GenerateApiRequest } from "@/data/payload/api/StiDestScApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";

export interface StiDestScState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  stiDestScList: StiDestScEntities;
  isLoadingPrintDataList: boolean;
  isErrorPrintList: boolean;
  isErrorCausePrintList: string;
  printDataList: PrintDataList;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "incoming-sti-dest-sc"
})
class StiDestScStore extends VuexModule implements StiDestScState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public isOpenSuccess = false;
  public isFailed = false;
  public filter = {
    startDate: "",
    endDate: "",
    search: "",
    destinationCityCode: ""
  };
  public stiDestScList = new StiDestScEntities(new Pagination(1, 10), []);
  public isLoadingPrintDataList = false;
  public isErrorPrintList = false;
  public isErrorCausePrintList = "";
  public printDataList = new PrintDataList(
    0,
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    "",
    "",
    "",
    []
  );
  public stiDestScIdToGeneratePdf = 0;
  public sttFailed: Array<string> = [];

  @Action
  getStiDestScList(params: {
    page: number;
    limit: number;
    search: string;
    startDate: string;
    endDate: string;
  }) {
    this.setIsLoading(true);
    const presenter = container.resolve(StiDestScPresenter);
    return presenter
      .getListStiDestSc(
        params.page,
        params.limit,
        params.startDate,
        params.endDate,
        params.search
      )
      .then((res: StiDestScEntities) => {
        this.setStiDestSc(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setStiDestSc(new StiDestScEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchStiDestScList() {
    this.getStiDestScList({
      page: this.stiDestScList.pagination.page,
      limit: this.stiDestScList.pagination.limit,
      search: this.filter.search,
      startDate: new Date(this.filter.startDate).toLocaleDateString("fr-CA"),
      endDate: new Date(this.filter.endDate).toLocaleDateString("fr-CA")
    });
  }

  @Action
  public getPrintDataList(params: { id: number }) {
    this.setLoadingPrintDataList(true);
    const presenter = container.resolve(StiDestScPresenter);
    return presenter
      .getPrintDataList(params.id)
      .then((res: PrintDataList) => {
        this.setGetPrintDataList(res);
        return true;
      })
      .catch((error: any) => {
        this.setErrorPrintList(true);
        this.setErrorCausePrintList(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setLoadingPrintDataList(false);
      });
  }

  @Action
  public getDetailSttStiDestSc(params: {
    sttOrBagNumber: string;
    callback: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestScPresenter);
    return presenter
      .getDetailSttStiDestSc(params.sttOrBagNumber)
      .then((res: DetailSttDestScInformation[]) => {
        params.callback(res);
        return res;
      })
      .catch(err => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
            this.getDetailSttStiDestSc(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public onGenerateStiDestSc(params: { sttNumber: Array<string> }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestScPresenter);
    return presenter
      .generateStiDestSc(new GenerateApiRequest(params.sttNumber))
      .then((res: ResponsePayload) => {
        this.setStiDestScIdToGeneratePdf(res.data.sti_dest_sc_id);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.onGenerateStiDestSc({
              sttNumber: params.sttNumber
            });
          })
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  private setStiDestScIdToGeneratePdf(value: number) {
    this.stiDestScIdToGeneratePdf = value;
  }

  @Mutation
  private setStiDestSc(data: StiDestScEntities) {
    this.stiDestScList = data;
  }

  @Mutation
  private setGetPrintDataList(data: PrintDataList) {
    this.printDataList = data;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setLoadingPrintDataList(value: boolean) {
    this.isLoadingPrintDataList = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setDestinationCityCode(value: string) {
    this.filter.destinationCityCode = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorPrintList(val: boolean) {
    this.isErrorPrintList = val;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setErrorCausePrintList(val: string) {
    this.isErrorCausePrintList = val;
  }

  @Mutation
  public setSttFailed(value: Array<string>) {
    this.sttFailed = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.isOpenSuccess = value;
  }

  @Mutation
  public setFailed(value: boolean) {
    this.isFailed = value;
  }
}

export const StiDestScController = getModule(StiDestScStore);
