import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  dateToDateString,
  convertDecimalAfterComma,
  formatPriceWithoutCurrency,
  ellipsisString,
  convertDecimalWithComma
} from "@/app/infrastructures/misc/Utils";
import { PrintDataList } from "@/domain/entities/StiDestSc";

let doc: any;

const drawCellConditions = (cell: any) => [
  cell.column.dataKey === "grossWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "volumeWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "productType" && cell.row.section === "head",
  cell.column.dataKey === "productType" && cell.row.section === "body",
  cell.column.dataKey === "origin" && cell.row.section === "body",
  cell.column.dataKey === "destination" && cell.row.section === "body"
];

const drawCellData = (cell: any) => {
  if (cell.row.index < 0) {
    return;
  }
  if (drawCellConditions(cell)[0]) {
    cell.row.cells["grossWeight"].styles = {
      ...cell.row.cells["grossWeight"].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[1]) {
    cell.row.cells["volumeWeight"].styles = {
      ...cell.row.cells["volumeWeight"].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[2]) {
    cell.row.cells["productType"].styles = {
      ...cell.row.cells["productType"].styles,
      cellPadding: {
        ...cell.row.cells["productType"].styles.cellPadding,
        left: 0.7
      }
    };
  }

  if (drawCellConditions(cell)[3]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["productType"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["productType"].x + 0.6, //x
          cell.row.cells["productType"].y + //y
            cell.row.cells["productType"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["origin"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["productType"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }

  if (drawCellConditions(cell)[4]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["origin"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["origin"].x + 0.6, //x
          cell.row.cells["origin"].y + //y
            cell.row.cells["origin"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["destination"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["origin"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }

  if (drawCellConditions(cell)[5]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["destination"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["destination"].x + 0.6, //x
          cell.row.cells["destination"].y + //y
            cell.row.cells["destination"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["origin"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["destination"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const generateManifest = (head: PrintDataList, data: any, logo: string) => {
  doc = new jsPDF("p", "mm", "a4", true);
  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("STI DEST-SC Manifest", 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 190, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  // left column
  doc.text("Nama Sub Consolidator", 10, 31);
  doc.text(":", 43, 31);
  doc.text(ellipsisString(head.stiDestPartnerName, 70), 45, 31);

  doc.setTextColor("#4d4d4d");
  doc.text("Kota Kedatangan", 10, 37);
  doc.text(":", 43, 37);
  doc.text(`${head.stiDestCityCode} - ${head.stiDestCityName}`, 45, 37);

  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal STI DEST-SC", 10, 43);
  doc.text(":", 43, 43);
  doc.text(dateToDateString(head.stiDestCreatedAt), 45, 43);

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT", 110, 31);
  doc.text(":", 138, 31);
  doc.text(String(head.stiDestTotalStt), 140, 31);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Koli", 110, 37);
  doc.text(":", 138, 37);
  doc.text(String(head.stiDestTotalPieces), 140, 37);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Kotor", 110, 43);
  doc.text(":", 138, 43);
  doc.text(
    `${formatPriceWithoutCurrency(
      Number(convertDecimalAfterComma(head.stiDestTotalGrossWeight, 2))
    )} Kg`,
    140,
    43
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Dimensi", 110, 49);
  doc.text(":", 138, 49);
  doc.text(
    `${formatPriceWithoutCurrency(
      Number(convertDecimalAfterComma(head.stiDestTotalVolumeWeight, 2))
    )} Kg`,
    140,
    49
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTable = [
    {
      header: "No.",
      dataKey: "no"
    },
    {
      header: "No. Bag",
      dataKey: "bagNumber"
    },
    {
      header: "No. STT",
      dataKey: "sttNumber"
    },
    {
      header: "Produk",
      dataKey: "productType"
    },
    {
      header: "Komoditas",
      dataKey: "commodity"
    },
    {
      header: "Total\nKoli",
      dataKey: "totalPieces"
    },
    {
      header: "Berat\nKotor",
      dataKey: "grossWeight"
    },
    {
      header: "Berat\nDimensi",
      dataKey: "volumeWeight"
    },
    {
      header: "Origin",
      dataKey: "origin"
    },
    {
      header: "Dest.",
      dataKey: "destination"
    },
    {
      header: "Catatan",
      dataKey: "notes"
    }
  ];

  const columnStyles = {
    0: { cellWidth: 8 },
    1: { cellWidth: 25 },
    2: { cellWidth: 25 },
    3: { cellWidth: 20 },
    4: { cellWidth: 32 },
    5: { cellWidth: 13 },
    6: { cellWidth: 13 },
    7: { cellWidth: 13 },
    8: { cellWidth: 13 },
    9: { cellWidth: 13 },
    10: { cellWidth: 15 }
  };

  doc.autoTable({
    body: data,
    columns: columnsTable,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 55 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Station Admin", 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text("Kurir", 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text("Operations Supervisor", 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  doc.setProperties({
    title: "STI DEST-SC Manifest"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const manifestPdfV2 = (printData: PrintDataList, logo: any) => {
  const data: Array<any> = [];
  let no = 1;
  for (const e of printData.stiDestDetail) {
    data.push({
      no: no,
      bagNumber: e.bagNo,
      sttNumber: e.sttNo,
      productType: e.product,
      commodity: e.commodity,
      totalPieces: e.totalPiece,
      grossWeight: `${convertDecimalWithComma(e.grossWeight, 2)} Kg`,
      volumeWeight: `${convertDecimalWithComma(e.volumeWeight, 2)} Kg`,
      origin: e.originCityCode,
      destination: e.destinationCityCode,
      notes: ""
    });
    no++;
  }
  generateManifest(printData, data, logo);
};

export default manifestPdfV2;
