import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_3 = { class: "flex z-20 items-center justify-between mt-8 mb-2" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Print = _resolveComponent("Print")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb),
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, _toDisplayString(_ctx.getTitle), 1)
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Search, {
            class: "self-center",
            style: {"width":"17rem"},
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            placeholder: "Cari No. Kargo"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_time_range_picker, {
            default: "7 hari terakhir",
            class: "mr-4",
            startDate: _ctx.startDate,
            "onUpdate:startDate": _cache[1] || (_cache[1] = ($event: any) => (_ctx.startDate = $event)),
            endDate: _ctx.endDate,
            "onUpdate:endDate": _cache[2] || (_cache[2] = ($event: any) => (_ctx.endDate = $event)),
            onSelect: _ctx.setDateRange
          }, null, 8, ["startDate", "endDate", "onSelect"])
        ]),
        (_ctx.ableToCreate)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              onClick: _ctx.goToUpdate,
              title: "Update STI DEST-SC",
              textColor: "white"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      (
        _ctx.stiDestScList.length === 0 && !_ctx.searchValue && !_ctx.startDate && !_ctx.endDate
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "station transit in destination sub consolidator"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.stiDestScList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => (_ctx.pagination = $event)),
            totalColumnFreeze: 1,
            isRightFreeze: true,
            "border-top": false,
            pinnedSubstractHeight: "295px",
            onRequest: _ctx.fetchStiDestScList,
            errorCause: _ctx.errorCause,
            customButtonAction: _ctx.customButton
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "customButtonAction"]))
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ])
  ], 64))
}